import React from 'react';
import { useKeycloak } from '@react-keycloak/web'

import { RecordingType, Metadatum } from './types/models';

import TimeSeriesCard from './components/TimeSeriesCardProps';

import axios from 'axios';

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import Loader from "react-loader-spinner";
import './App.scss';
import SettingsCard from './components/SettingsCard';
import {
  Configuration, Campaign, CampaignsApi, Sensor, SensorsApi, Location, LocationsApi, Area, AreasApi, Deployment, DeploymentsApi, Site, SitesApi
} from '@wavely/deployment-fastapi-sdk'

export const basePath = process.env.REACT_APP_DEPLOYMENT_API_URL || "";

const App: React.FC = () => {
  const { keycloak, initialized } = useKeycloak()

  const configuration = new Configuration({
    basePath: basePath,
    accessToken: keycloak.token || "",
  })

  const [allCampaigns, setAllCampaigns] = React.useState<Campaign[]>([]);
  const [allLocations, setAllLocations] = React.useState<Location[]>([]);
  const [allSites, setAllSites] = React.useState<Site[]>([]);
  const [allAreas, setAllAreas] = React.useState<Area[]>([]);
  const [allDeployments, setAllDeployments] = React.useState<Deployment[]>([]);
  const [allSensors, setAllSensors] = React.useState<Sensor[]>([]);

  const [loading, setLoading] = React.useState<boolean>(true);

  const [campaign, setCampaign] = React.useState<Campaign>();
  const [locations, setLocations] = React.useState<Location[]>([]);

  const [startDate, setStartDate] = React.useState<Date>();
  const [endDate, setEndDate] = React.useState<Date>();
  const [recordingTypes, setRecordingTypes] = React.useState<RecordingType[]>(
    [],
  );
  const [allMetadata, setAllMetadata] = React.useState<Metadatum[]>([]);

  React.useEffect(() => {
    if (!campaign) return

    setLoading(true)
    setLocations(allLocations.filter(l => l.campaign_id === campaign.id))
  }, [campaign])

  React.useEffect(() => {
    if (locations.length === 0) return

    const deployments = allDeployments.filter(d => (d.location_id in locations.map(l => l.id)) && d.ts_range[1])
    const sensors = allSensors.filter(s => s.id in deployments.map(d => d.sensor_id))

    const promises = sensors.map(s => axios.get<Metadatum[]>(`${basePath}/api/magnetos/${s.id}/metadata`, { timeout: 60000, headers: { "Authorization": `Bearer ${keycloak.token}` } }))
    Promise.allSettled(promises).then(res => {
      setAllMetadata(res.flatMap((event: any) => {
        return event.value && event.value.data ? event.value.data : []
      }))
      setLoading(false)
    })

  }, [locations])

  React.useEffect(() => {
    if (!keycloak.authenticated) return
    setLoading(true)
    Promise.all([
      new CampaignsApi(configuration).listCampaignsApiCampaignsGet(),
      new LocationsApi(configuration).listLocationsApiLocationsGet(),
      new DeploymentsApi(configuration).listDeploymentsApiDeploymentsGet(),
      new SitesApi(configuration).listSitesApiSitesGet(),
      new AreasApi(configuration).listAreasApiAreasGet(),
      new SensorsApi(configuration).listSensorsApiSensorsGet()
    ]).then(([campaignsResult, locationsResult, deploymentsResult, sitesResult, areasResult, sensorsResult]) => {
      setAllCampaigns(campaignsResult.data)
      setAllLocations(locationsResult.data)
      setAllDeployments(deploymentsResult.data)
      setAllSites(sitesResult.data)
      setAllAreas(areasResult.data)
      setAllSensors(sensorsResult.data)

      return campaignsResult
    }).then((campaignsResult) => {
      if (campaignsResult && campaignsResult.data.length != 0) setCampaign(campaignsResult.data[0])
    })
  }, [keycloak.authenticated])

  const renderSeriesSection = (location: Location) => {
    const site = allSites.find(s => s.id === location.site_id);
    const area = allAreas.find(a => a.id === location.area_id);

    const deployments = allDeployments.filter(d => (d.location_id === location.id) && d.ts_range[0])

    const metadata = allMetadata.filter(m => deployments.map(d => d.sensor_id).indexOf(parseInt(m.sensor)) > -1)

    if (deployments.length === 0 || metadata.length === 0) {
      return
    }

    return (
      <TimeSeriesCard
        basePath={basePath}
        key={location.id}
        location={location}
        site={site}
        area={area}
        deployments={deployments}
        startDate={startDate}
        endDate={endDate}
        recordingTypes={recordingTypes}
        metadata={metadata}
      />
    )
  }

  return (
    <div className="app">
      <SettingsCard
        campaigns={allCampaigns}
        setCurrentCampaign={setCampaign}
        setEndDate={setEndDate}
        setStartDate={setStartDate}
        endDate={endDate}
        startDate={startDate}
        currentCampaign={campaign}
        setRecordingTypes={setRecordingTypes}
        recordingTypes={recordingTypes}
        disabled={!loading}
      />
      <div className="time-series-section">
        {loading ? <div style={{ marginTop: "50px" }}><Loader
          type="Rings"
          color="#00a6b9"
          height={50}
          width={50}
        /></div> : locations.map(location => renderSeriesSection(location))}
      </div>
    </div>
  );
};

export default App;
