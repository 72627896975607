import React from 'react';

import moment from 'moment';

import {
  AnchorButton,
  Button,
  Card,
  H5,
  Intent,
  Popover,
  PopoverInteractionKind,
  Tag,
  Text,
} from '@blueprintjs/core';

import VirtualizedTable from './VirtualizedTable';

import { Area, Deployment, Location, Site, Sensor } from '@wavely/deployment-fastapi-sdk';
import { Metadatum, RecordingType } from '../types/models';

interface TimeSeriesCardProps {
  site?: Site;
  area?: Area;
  location: Location;
  startDate?: Date;
  endDate?: Date;
  recordingTypes: RecordingType[];
  deployments: Deployment[];
  metadata: Metadatum[]
  basePath: string;
}

const TimeSeriesCard = (props: TimeSeriesCardProps) => {
  let timeRangeEvents = props.metadata.filter(m => {
    const startTime = new Date(m.startTime).getTime();
    return (
      (!props.startDate || startTime >= props.startDate.getTime()) &&
      (!props.endDate || startTime <= props.endDate.getTime())
    );
  });

  if (props.recordingTypes.length > 0) {
    timeRangeEvents = timeRangeEvents.filter(e =>
      props.recordingTypes.includes(e.recordingType),
    );
  }

  const timeRangeDeployments = props.deployments.filter(d => {
    // TODO(vmttn): check that there is an actual startDate
    const startDate = new Date(d.ts_range[0]!).getTime();
    const endDate = d.ts_range[1]
      ? new Date(d.ts_range[1]).getTime()
      : new Date().getTime();
    return (
      (!props.startDate || endDate >= props.startDate.getTime()) &&
      (!props.endDate || startDate <= props.endDate.getTime())
    );
  });

  return (
    <Card
      key={props.location && props.location.id}
      className="time-series-card"
      interactive={true}
    >
      <div className="card-header">
        <H5>{props.location && props.location.name}</H5>
        <div className="tags-group">
          {props.site && (
            <Tag icon="map" minimal={true}>
              {props.site.name}
            </Tag>
          )}
          {props.area && (
            <Tag icon="polygon-filter" minimal={true}>
              {props.area.name}
            </Tag>
          )}
        </div>
        <Text>{timeRangeDeployments && timeRangeDeployments.length} deployments</Text>
        <Text>{timeRangeEvents.length} events</Text>
        <Popover interactionKind={PopoverInteractionKind.HOVER}>
          <Button icon="list" intent={Intent.PRIMARY}>
            List
          </Button>
          <div className="event-table">
            <VirtualizedTable
              rows={timeRangeEvents}
              renderHeaders={() => (
                <tr>
                  <th>Sensor</th>
                  <th>Datetime</th>
                  <th />
                </tr>
              )}
              renderRow={row => (
                <tr key={row.id}>
                  <td>{row.sensor}</td>
                  <td>
                    <span style={{ whiteSpace: 'nowrap' }}>
                      {moment(row.startTime).calendar()}
                    </span>
                  </td>
                  <td>
                    <AnchorButton
                      icon="download"
                      href={`${props.basePath}/api/magnetos/${row.sensor}/recordings/${row.id}`}
                      download={`${row.id}.wav`}
                    />
                  </td>
                </tr>
              )}
            />
          </div>
        </Popover>
      </div>
    </Card>
  );
};

export default TimeSeriesCard;
