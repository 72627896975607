import React from 'react';

import { HTMLTable } from '@blueprintjs/core';

interface VirtualizedTableProps {
  rows: Array<any & { id: string }>;
  renderHeaders: () => React.ReactNode;
  renderRow: (row: any) => React.ReactNode;
  // height: number;
  // rowHeight: number;
}

// TODO(vmatt): actually virtualized

const VirtualizedTable = (props: VirtualizedTableProps) => {
  const height = 300;

  return (
    <div className="virtualized-table-wrapper" style={{ maxHeight: height }}>
      <HTMLTable condensed={true} interactive={true} striped={true}>
        <thead>{props.renderHeaders()}</thead>
        <tbody>{props.rows.map(row => props.renderRow(row))}</tbody>
      </HTMLTable>
    </div>
  );
};

export default VirtualizedTable;
