import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Navbar } from '@blueprintjs/core';

import { ReactKeycloakProvider } from '@react-keycloak/web'

import keycloak from './keycloak'

ReactDOM.render(
  <ReactKeycloakProvider authClient={keycloak} initOptions={{ onLoad: 'login-required' }}>
    <Navbar>
      <Navbar.Group align="left">
        <Navbar.Heading>Soundbooth</Navbar.Heading>
      </Navbar.Group>
      <Navbar.Group align="right">
      </Navbar.Group>
    </Navbar>
    <App />
  </ReactKeycloakProvider >,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
