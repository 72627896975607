export enum RecordingType {
  PERIODIC = 'periodic',
  EVENT_DETECTION = 'event_detection',
}

export interface Metadatum {
  id: string;
  sensor: string;
  startTime: string;
  recordingType: RecordingType;
}
